<template>
    <div class="flexPage">
        <navbar :params="pageInfo" />
        <div class="service">
            <h3>客服服务</h3>
            <p>点击右下角的按钮</p>
        </div>
    </div>
</template>
<script>
import navbar from "@/views/modules/navbar.vue";

export default {
    data() {
        return {
            pageInfo: {
                customTitle: this.$t('home.i5'),
                navbarStyle: 'normal',
                backname: "home"
            },
        }
    },
    components: { navbar },
    mounted() {
        this.getCustomerService();
    },
    methods: {
        getCustomerService() {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = 'https://api.pollychat.com/snippet.js?templateId=1764313898262986752';

            script.onload = () => {
                // 外部脚本加载完成后在这里进行相关操作
            };

            document.body.appendChild(script);
        }
    },
    beforeDestroy() {
        window.location.reload();
    }
}
</script>

<style>
.service {
    margin-top: 10.1781vw;
    text-align: center;
}

.service h3 {
    font-weight: 510;
    font-size: 4.5802vw;
}

.service p {
    margin-top: 3.8168vw;
    font-size: 3.5623vw;
}
</style>